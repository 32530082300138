












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SinglePerson extends Vue {
	@Prop({ required: true, type: String }) src!: string;
	@Prop({ required: true, type: String }) name!: string;
	@Prop({ required: false, type: String }) alt: string | undefined;
	@Prop({ required: false, type: String }) office: string | undefined;
	@Prop({ required: false, type: String }) phone: string | undefined;
	@Prop({ required: false, type: String }) email: string | undefined;
}
